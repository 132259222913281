<template>
  <div :class="['all1', isBeiJing && 'is-beijing']">
    <div class="bingdu">病毒<br />消杀率</div>
    <div class="huan1"></div>
    <div class="huan2"></div>
    <div class="huan3">
      <div class="qiu1"></div>
      <span class="pl12">{{ obj.coronavirusSterilization }}<i>%</i> <br /> <span>新冠病毒</span> </span>
      <div class="qiu2"></div>
      <span>{{ obj.aureusSterilization }}<i>%</i><br /><span>白葡萄球菌</span> </span>
      <div class="qiu3"></div>
      <span>{{ obj.naturalSterilization }}<i>%</i><br /><span>自然菌</span> </span>

      <div class="qiu4"></div>
      <span>{{ obj.h1N1Sterilization }}<i>%</i><br /><span>H1N1病毒</span> </span>

      <div class="qiu5"></div>
      <span>{{ obj.humanSterilization }}<i>%</i><br /><span>人冠状病毒</span> </span>

    </div>

  </div>
</template>

<script>

export default {
  props: {
    obj: {
      type: Object,
      default: () => ({})
    },
    isBeiJing: Boolean
  }
}
</script>

<style lang="scss" scoped>
.all1 {
  width: 477px;
  height: 560px;
  background: url("../../../assets/img/切图4.0/蜂窝背景.png") no-repeat;
  background-size: 100%;
  position: relative;
  right: 4%;
  top: 4%;
  transform: scale(0.9);

  &.is-beijing {
    right: 6%;
    top: 22%;
    transform: scale(1.05);
  }
}

.bingdu {
  width: 125px;
  height: 125px;
  background: url("../../../assets/img/切图4.0/  发光小圆.png") no-repeat;
  background-size: 100%;
  text-align: center;
  font-size: 32px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #41f5de;
  line-height: 40px;
  padding-top: 20px;
  position: absolute;
  top: 35%;
  left: 46%;
}

.huan1 {
  width: 184px;
  height: 184px;
  position: absolute;
  background: url("../../../assets/img/切图4.0/小齿轮圆.png") no-repeat;
  background-size: 100%;
  text-align: center;
  top: 30%;
  left: 40%;
  animation: running 20s linear infinite;
}

.huan2 {
  width: 228px;
  height: 228px;
  position: absolute;
  background: url("../../../assets/img/切图4.0/虚线发光圆.png") no-repeat;
  background-size: 100%;
  text-align: center;
  top: 26%;
  left: 35%;
  animation: running1 20s linear infinite;
}

.huan3 {
  width: 296px;
  height: 296px;
  position: absolute;
  background: url("../../../assets/img/切图4.0/大齿轮圆.png") no-repeat;
  background-size: 100%;
  text-align: center;
  top: 20%;
  left: 27.5%;
  // transform: rotateZ(-290deg);
  animation: running 20s linear infinite;

  span:nth-child(2) {
    font-size: 28px;
    font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
    font-weight: bold;
    color: #63ffee;
    line-height: 24px;

    position: absolute;
    top: -32%;
    left: 31.5%;

    i {
      font-style: normal;
      font-size: 15px;
    }

    span {
      font-size: 16px;
      margin-left: 5%;
    }
  }

  span:nth-child(4) {
    font-size: 28px;
    font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
    font-weight: bold;
    color: #63ffee;
    line-height: 24px;
    transform: rotateZ(72deg);

    position: absolute;
    top: 19%;
    right: -36%;

    i {
      font-style: normal;
      font-size: 15px;
    }

    span {
      font-size: 16px;
    }
  }

  span:nth-child(6) {
    font-size: 28px;
    font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
    font-weight: bold;
    color: #63ffee;
    line-height: 24px;
    transform: rotateZ(145deg);

    position: absolute;
    top: 104%;
    left: 76%;

    i {
      font-style: normal;
      font-size: 15px;
    }

    span {
      font-size: 16px;
      margin-left: -10%;
    }
  }

  span:nth-child(8) {
    font-size: 28px;
    font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
    font-weight: bold;
    color: #63ffee;
    line-height: 24px;
    transform: rotateZ(218deg);

    position: absolute;
    top: 99%;
    left: -16%;

    i {
      font-style: normal;
      font-size: 15px;
    }

    span {
      font-size: 16px;
    }
  }

  span:nth-child(10) {
    font-size: 28px;
    font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
    font-weight: bold;
    color: #63ffee;
    line-height: 24px;
    transform: rotateZ(-70deg);

    position: absolute;
    top: 13%;
    left: -34%;

    i {
      font-style: normal;
      font-size: 15px;
    }

    span {
      font-size: 16px;
      margin-left: -8%;
    }
  }
}

.qiu1 {
  width: 212px;
  height: 212px;
  background: url("../../../assets/img/切图4.0/发光球1.png") no-repeat;
  background-size: 100%;
  text-align: center;
  position: absolute;
  top: -62%;
  left: 16%;

  animation: running 20s linear infinite;
}

.qiu2 {
  width: 212px;
  height: 212px;
  background: url("../../../assets/img/切图4.0/发光球1.png") no-repeat;
  background-size: 100%;
  text-align: center;
  position: absolute;
  right: -59%;
  top: -8%;
  animation: running 20s linear infinite;
}

.qiu3 {
  width: 212px;
  height: 212px;
  background: url("../../../assets/img/切图4.0/发光球1.png") no-repeat;
  background-size: 100%;
  text-align: center;
  position: absolute;
  right: -63%;
  top: 78%;
  right: -30%;

  animation: running 20s linear infinite;
}

.qiu4 {
  width: 212px;
  height: 212px;
  background: url("../../../assets/img/切图4.0/发光球1.png") no-repeat;
  background-size: 100%;
  text-align: center;
  position: absolute;
  right: -63%;
  top: 74%;
  left: -35%;

  animation: running 20s linear infinite;
}

.qiu5 {
  width: 212px;
  height: 212px;
  background: url("../../../assets/img/切图4.0/发光球1.png") no-repeat;
  background-size: 100%;
  text-align: center;
  position: absolute;
  right: -63%;
  top: -13%;
  left: -58%;

  animation: running 20s linear infinite;
}

@keyframes running {
  0% {}

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes running1 {
  0% {}

  100% {
    transform: rotateZ(-360deg);
  }
}
</style>