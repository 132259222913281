<template>
  <div class="cont" ref="cont"></div>
</template>

<script>
import * as echarts from "echarts";
import { throttle } from "@/utils/pubilcjs.js";
export default {
  data() {
    return {
      myChart: null,
      isRefreshing: false
    };
  },
  props: {
    heat: {
      type: [Number, String],
      default: 0,
    },
    humidity: {
      type: [Number, String],
      default: 0,
    },
    quality: {
      type: String,
      default: "优"
    },
    addPosition: {
      type: String,
      default: "end"
    },
    triangle: {
      type: Boolean,
      default: false
    }
  },
  beforeUnmount() {
    clearInterval(this.timer1);
  },
  mounted() {
    window.addEventListener(
      "resize",
      throttle(() => {
        this.myChart && this.myChart.resize();
      }, 500)
    );
    this.getAverage();
  },
  watch: {
    heat() {
      this.getAverage();
    },
    humidity() {
      this.getAverage();
    },
    quality() {
      this.getAverage();
    }
  },

  methods: {
    getAverage() {
      if (this.isRefreshing) return
      this.isRefreshing = true
      const myChart = this.myChart || echarts.init(this.$refs.cont);

      const opt = [
        {
          radius: "40%",
          center: ["25%", "30%"],
        },
        {
          radius: "40%",
          center: ["75%", "30%"],
        },
        {
          radius: "40%",
          center: ["50%", "70%"],
        }
      ]

      const option = {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },
        series: [
          {
            name: "Quality",
            type: "gauge",
            radius: "90%",

            detail: {
              formatter: "{value}",
              color: "#fff",
              fontWeight: 600,
              fontSize: 60,
              fontFamily: "DS-Digital-BoldItalic, DS-Digital",
              offsetCenter: ["0", "-10%"],
            },
            data: [
              {
                value: Number(this.heat),
                name: "温度℃",
              },
            ],
            endAngle: -15,
            colorBy: "data",
            center: ["16%", "51%"],
            startAngle: 195,
            min: -10,
            max: 50,
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              roundCap: true,

              lineStyle: {
                color: [
                  [
                    1,
                    new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: "rgba(145, 190, 109, 1)",
                      },
                      {
                        offset: 0.43,
                        color: "rgba(249, 182, 78, .85)",
                      },
                      {
                        offset: 0.85,
                        color: "rgba(249, 182, 78, 1)",
                      },
                      {
                        offset: 1,
                        color: "rgba(249, 65, 68, 1)",
                      },
                    ]),
                  ],
                ],
                width: 8,
              },
            },

            // 刻度样式
            axisTick: {
              show: false,
              splitNumber: 5,
              lineStyle: {
                color: "#ffffff",
              },
            },
            // 刻度标签
            axisLabel: {
              show: false,
              distance: 25,
              formatter: function (value) {
                if (value % 20 === 0) return value + "%";
              },
              color: "#ffffff",
              fontFamily: "DS-Digital-BoldItalic, DS-Digital",
              fontSize: 10,
            },
            // 指针
            pointer: {
              icon: "circle",
              width: 15,
              offsetCenter: [0, "-66%"],
              itemStyle: {
                color: "rgba(255, 255, 255, 1)",
                borderWidth: 1,
                borderColor: "rgba(0,0,0,.5)",
              },
            },
            title: {
              fontSize: 20,
              offsetCenter: ["0", "70%"],
              color: "rgba(255, 255, 255, 1)",
              fontWeight: 400,
            },
            ...this.triangle ? opt[0] : {}
          },
          {
            name: "Quality",
            type: "gauge",
            radius: "90%",

            detail: {
              formatter: "{value}",
              color: "#fff",
              fontWeight: 600,
              fontSize: 60,
              fontFamily: "DS-Digital-BoldItalic, DS-Digital",
              offsetCenter: ["0", "-10%"],
            },
            data: [
              {
                value: Number(this.humidity),
                name: "湿度%RH",
              },
            ],
            endAngle: -15,
            colorBy: "data",
            center: ["50%", "51%"],
            startAngle: 195,
            min: 0,
            max: 100,
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              roundCap: true,

              lineStyle: {
                color: [
                  [
                    1,
                    new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: "rgba(249, 65, 68, 1)",
                      },
                      {
                        offset: 0.1,
                        color: "rgba(249, 199, 80, 1)",
                      },
                      {
                        offset: 0.3,
                        color: "rgba(145, 190, 109, 1)",
                      },
                      {
                        offset: 0.7,
                        color: "rgba(145, 190, 109, 1)",
                      },
                      {
                        offset: 0.9,
                        color: "rgba(249, 199, 80, 1)",
                      },

                      {
                        offset: 1,
                        color: "rgba(249, 65, 68, 1)",
                      },
                    ]),
                  ],
                ],
                width: 8,
              },
            },

            // 刻度样式
            axisTick: {
              show: false,
              splitNumber: 5,
              lineStyle: {
                color: "#ffffff",
              },
            },
            // 刻度标签
            axisLabel: {
              show: false,
              distance: 25,
              formatter: function (value) {
                if (value % 20 === 0) return value + "%";
              },
              color: "#ffffff",
              fontFamily: "Arial",
              fontSize: 10,
            },
            // 指针
            pointer: {
              icon: "circle",
              width: 15,
              offsetCenter: [0, "-66%"],
              itemStyle: {
                color: "rgba(255, 255, 255, 1)",
                borderWidth: 1,
                borderColor: "rgba(0,0,0,.5)",
              },
            },
            title: {
              fontSize: 20,
              offsetCenter: ["0", "70%"],
              color: "rgba(255, 255, 255, 1)",
              fontWeight: 400,
            },
            ...this.triangle ? opt[1] : {}
          },
        ],
      };

      option.series.push({
        name: "Quality",
        type: "gauge",
        radius: "90%",

        detail: {
          formatter: this.quality,
          color: this.quality === "优" ? '#2CFF84' : '#F7B500',
          fontWeight: 600,
          fontSize: 40,
          fontFamily: "DS-Digital-BoldItalic, DS-Digital",
          offsetCenter: ["0", "-10%"],
        },
        data: [
          {
            value: this.quality === "优" ? 20 : 80,
            name: "空气质量",
          },
        ],
        endAngle: -15,
        colorBy: "data",
        center: ["84%", "51%"],
        startAngle: 195,
        min: 0,
        max: 100,
        splitLine: {
          show: false,
        },
        axisLine: {
          show: true,
          roundCap: true,
          lineStyle: {
            color: [
              [
                1,
                new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "rgba(145, 190, 109, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(249, 199, 80, 1)",
                  },
                ]),
              ],
            ],
            width: 8,
          },
        },

        // 刻度样式
        axisTick: {
          show: false,
          splitNumber: 5,
          lineStyle: {
            color: "#ffffff",
          },
        },
        // 刻度标签
        axisLabel: {
          show: false,
          distance: 25,
          formatter: function (value) {
            if (value % 20 === 0) return value + "%";
          },
          color: "#ffffff",
          fontFamily: "Arial",
          fontSize: 10,
        },
        // 指针
        pointer: {
          icon: "circle",
          width: 15,
          offsetCenter: [0, "-66%"],
          itemStyle: {
            color: "rgba(255, 255, 255, 1)",
            borderWidth: 1,
            borderColor: "rgba(0,0,0,.5)",
          },
        },
        title: {
          fontSize: 20,
          offsetCenter: ["0", "70%"],
          color: "rgba(255, 255, 255, 1)",
          fontWeight: 400,
        },
        ...this.triangle ? opt[2] : {}
      })

      if (this.addPosition === "start") {
        const center = option.series[0].center.slice(0)
        option.series[0].center = option.series[1].center.slice(0)
        option.series[1].center = option.series[2].center.slice(0)
        option.series[2].center = center
      }

      option && myChart.setOption(option);
      this.myChart = myChart;

      this.isRefreshing = false
    },
  },
};
</script>

<style lang="scss" scoped>
.cont {
  height: 180px;
}
</style>