<template>
  <div class="toilet-antivirus">
    <Top hasRightSlot>
      <template #right>
        <span style="color:#fff;">重庆铭达鸿智生物科技有限公司</span>
      </template>
    </Top>
    <div @click="lagout" class="showback"></div>
    <div class="container flex">
      <div class="box_l flex1 h-max color-f">
        <div class="title">消毒设备运行状态</div>
        <div class="situation left male">
          <div class="img"></div>
          <div class="des">
            <div>
              运行状态：
              <span :class="['status', data.devStatus ? 'run' : 'stop']">
                {{ devStatusTip }}
              </span>
            </div>
            <div>设备编码：{{ data.deviceId || data.deviceTwo || "-" }}</div>
          </div>
        </div>
        <div class="monitor male">
          <AverageQulity :heat="isMan ? data.tempValue : data.temp2Value"
            :humidity="isMan ? data.humiValue : data.humi2Value"
            :quality="isMan ? data.manAirQuality : data.womanAirQuality" triangle>
          </AverageQulity>
        </div>
      </div>
      <div class="box_center h-max pl20">
        <div>
          <div class="titleImg"></div>
          <div>
            <!-- <div class="air-quality text-center">
              <div class="fs14" style="color: #08F4DA;">空气安全指标(男)</div>
              <div class="fs36 p6" :style="`color: ${data.manAirQuality === '优' ? '#2CFF84' : '#F7B500'};`">{{
                data.manAirQuality }}</div>
            </div> -->
            <!-- <div style="flex: 1;"> -->
            <CountFlop :val="fullDigits(data.peopleCount)" title="今日人数" style="width: 100% !important;"></CountFlop>
            <!-- </div> -->
            <!-- <div class="air-quality text-center">
              <div class="fs14" style="color: #08F4DA;">空气安全指标(女)</div>
              <div class="fs36 p6" :style="`color: ${data.womanAirQuality === '优' ? '#2CFF84' : '#F7B500'};`">{{
                data.womanAirQuality }}</div>
            </div> -->
          </div>
        </div>
        <CenterCircle :obj="data" :isBeiJing="isBeijing"></CenterCircle>
      </div>
      <div class="box_r flex1 h-max">
        <div class="flex1">
          <div class="detail-cards flex1">
            <div class="d-card" v-for="it in detailCards" :key="it.key">
              <div class="dc-title">{{ it.tip }}</div>
              <div class="my-auto">
                <div class="dc-val">{{ isMan ? data[it.key + "Value"] : data[it.key + "2Value"] || "-" }}</div>
                <div class="dc-unit">{{ it.unit }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ta-bottom flex">
      <div class="hesuanTime flex1">
        <div class="hesuanTimeTitle">—— 消毒液用量 ——</div>
        <div class="disinfectionDosage flex">
          <div class="today flex1">
            <Circle :num="data.todayTrafficValue" color="#3AC8B7"></Circle>
            <span class="tip">今日用量(ml)</span>
          </div>
          <div class="all flex1">
            <Circle :num="data.trafficValue" color="#1DBD72"></Circle>
            <span class="tip">使用总量(ml)</span>
          </div>
        </div>
      </div>
      <div class="hesuanTime flex1 no-top" :style="isBeijing ? 'background: none;' : ''">
        <template v-if="!isBeijing">
          <div class="hesuanTimeTitle">—— 储存流量预警 ——</div>
          <div :class="['water flex', storageStatus.cls]">
            <ShuiQiu :num="data.remainder"></ShuiQiu>
            <div class="status">{{ storageStatus.tip }}</div>
          </div>
        </template>
      </div>
      <div :class="['hesuanTime flex1', data.devStatus ? 'run' : 'stop']">
        <div class="hesuanTimeTitle">—— 设备故障监测 ——</div>
        <div class="device-error flex">
          <!-- <div class="img">设备故障</div> -->
          <div class="info-card flex1 flex">
            <div class="info flex1">
              <span class="title">设备编码</span>
              <span class="ctx">-</span>
            </div>
            <div class="info flex1">
              <span class="title">故障原因</span>
              <span class="ctx">-</span>
            </div>
            <div class="info flex1">
              <span class="title">故障时间</span>
              <span class="ctx">-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Top from "@/components/top.vue";
import AverageQulity from "./components/averageQulity.vue";
import ShuiQiu from "./components/shuiqiu.vue";
import Circle from "./components/circle.vue";
import CenterCircle from "./components/display.vue";
import { getToiletData } from "@/api/kanban";
import CountFlop from "@/components/CountFlop";
export default {
  name: "SingleToiletAntivirusDevice",
  components: { Top, AverageQulity, ShuiQiu, Circle, CenterCircle, CountFlop },
  async created() {
    this.init();
    this.timer = setInterval(() => this.init(), 60000 * 15);
    // this.timer = setInterval(() => this.init(), 5000);
  },
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null
    }
  },
  computed: {
    storageStatus() {
      const val = this.data.remainder;

      if (val > 9)
        return {
          cls: "full",
          tip: "储量充足",
        };
      if (val > 1) {
        return {
          cls: "enough",
          tip: "储量有余",
        };
      } else {
        return {
          cls: "empty",
          tip: "储量不足",
        };
      }
    },
    devStatusTip() {
      if (this.data.devStatus === void 0) {
        return "-";
      }
      return this.data.devStatus ? "运行中" : "关闭中";
    },
  },
  data() {
    return {
      isBeijing: true,
      timer: null,
      data: {},
      detailCards: [
        {
          tip: "氨气",
          key: "ammo",
          unit: "mg/m³"
        },
        {
          tip: "硫化氢",
          key: "h2s",
          unit: "mg/m³"
        },
        {
          tip: "TVOC",
          key: "tvoc",
          unit: "mg/m³"
        },
      ],
      isMan: false
    };
  },
  methods: {
    async init() {
      const res = await getToiletData();

      this.isMan = res.data.isMaster === 2

      Object.assign(this.data, {
        ...res.data,
        h2s2Value: res.data.h2s2Vakue,
        // manAirQuality: Math.random() > 0.5 ? '优' : '良',
        // womanAirQuality: Math.random() > 0.5 ? '优' : '良',
        // remainder: parseInt(Math.random() * 1000),
        // todayTrafficValue: parseInt(Math.random() * 1000),
        // trafficValue: parseInt(Math.random() * 1000),
      });
    },
    fullDigits(num) {
      if (num) {
        return new Array(5 - num.toString().length).fill("0").join("") + num
      } else {
        return "00000"
      }
    },
    lagout() {
      localStorage.removeItem("toilet-auto-login");
      localStorage.removeItem("show_token");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
$errorColor: #e02020;

@keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.75;
  }
}

.toilet-antivirus {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
}

.container {
  width: 100%;
  height: 1004px;
  background: #000;
  perspective: 1800px;

  .title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #ffffff;
    line-height: 22px;
    margin: 0 8px 6px 4px;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      border: 2px solid #08f4da;
      margin-right: 8px;
    }

    i {
      display: inline-flex;
      height: 20px;
      width: 20px;
      margin-left: 10px;
      transform: translateY(2px);
    }

    .is-man {
      background: url("~@/assets/img/6.0/icon-man-blue.png") left center no-repeat;
      background-size: 100% 100%;
    }

    .is-woman {
      background: url("~@/assets/img/6.0/icon-woman-red.png") left center no-repeat;
      background-size: 100% 100%;
    }
  }

  .situation {
    height: 120px;
    background: linear-gradient(90deg,
        #13faef -200%,
        rgba(8, 244, 218, 0) 100%);
    border-radius: 12px;
    margin: 18px 0 50px 16px;
    display: flex;
    padding: 18px 8px;

    &.left {
      background: linear-gradient(-90deg,
          #13faef -200%,
          rgba(8, 244, 218, 0) 100%);
    }

    .img {
      width: 100px;
      height: 76px;
      background: url("~@/assets/img/4.0/healthyCodeImg/green.png");
      background-size: 100% 100%;
      margin-right: 40px;
    }

    .des {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-size: 24px;
      color: #fff;

      .status {
        font-size: 34px;
        color: #13faef;
        vertical-align: middle;

        &.stop {
          color: $errorColor;
        }
      }
    }
  }

  .monitor {
    margin-top: 30px;
    flex: 1;

    ::v-deep {
      .cont {
        height: 100%;
      }
    }
  }

  .box_l {
    padding: 50px 100px 0 2%;
    transform: rotateY(10deg);
    background: url("~@/assets/img/4.0/box_l.png") no-repeat;
  }

  .box_r {
    padding: 50px 50px 0 100px;
    transform: rotateY(-10deg);
    background: url("~@/assets/img/4.0/box_r.png") no-repeat;
  }

  .box_l,
  .box_r {
    background-size: 100% 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    margin-top: -10px;

    .detail-cards {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      flex-direction: column-reverse;
      justify-content: space-evenly;
      height: 100%;

      .d-card {
        background: url("~@/assets/img/5.0/bg-right.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        color: #08f4da;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 20%;
        padding: 0 40px;

        .dc-title {
          color: #fff;
          font-size: 32px;
        }

        .my-auto {
          display: flex;
          gap: 24px;
          align-items: center;
        }

        .dc-val {
          font-size: 36px;
          margin-bottom: 6px;
        }

        .dc-unit {
          font-size: 18px;
        }
      }
    }
  }

  .box_center {
    padding: 20px;
    box-sizing: border-box;
    width: 540px;

    .titleImg {
      width: 330px;
      height: 12px;
      background: url("~@/assets/img/切图4.0/头部配件.png") no-repeat;
      background-size: 100%;
      margin: 0 auto 14px auto;
    }

    .air-quality {
      background: rgba(8, 244, 218, 0.1);
      padding: 6px;

      .fs36 {
        background: rgba(#08F4DA, 0.1);
        margin-top: 12px;
      }
    }

    ::v-deep {
      .box {
        height: 102px !important;

        .fs20 {
          font-size: 14px !important;
          margin-bottom: 8px !important;
        }

        .count-flop {
          height: 54px;
          line-height: 54px;

          .count-flop-box {
            width: 38px;

            +.count-flop-box {
              margin-left: 8px;
            }
          }

          .count-flop-num {
            font-size: 54px !important;
            line-height: 54px;
          }
        }
      }
    }
  }
}

.ta-bottom {
  height: 300px;
  margin-top: -10px;
  z-index: 2;

  .hesuanTime {
    margin-top: 26px;
    padding-top: 20px;
    background: url("~@/assets/img/4.0/centerBottom.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;

    .hesuanTimeTitle {
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }

    .disinfectionDosage {
      gap: 20px;
      height: 140px;
      text-align: center;
      color: #3ac8b7;
      font-size: 16px;
      margin-top: 14px;

      .today .tip {
        padding: 0 14px 10px 14px;
        background: url("~@/assets/img/4.0/footerline_q.png") center no-repeat;
        background-size: 100% 100%;
      }

      .all {
        color: #1dbd72;

        .tip {
          padding: 0 14px 8px 14px;
          background: url("~@/assets/img/4.0/footerline_g.png") center no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .water {
      margin: auto 0;
      justify-content: center;

      .status {
        width: 250px;
        height: 80px;
        background: url("~@/assets/img/4.0/dialog.png") center;
        background-size: 120% 120%;
        margin: auto 0 auto 26px;
        line-height: 80px;
        text-align: center;
        font-size: 32px;
        border-radius: 12px;
      }

      &.full .status {
        color: #08f4da;
        background: rgba(#08f4da, 0.2);
        border: 2px solid #08f4da;
        border-left: none;
        border-right: none;
      }

      &.enough .status {
        color: #e6a23c;
        background: rgba(#e6a23c, 0.2);
        border: 2px solid #e6a23c;
        border-left: none;
        border-right: none;
      }

      &.empty .status {
        color: $errorColor;
        background: rgba($errorColor, 0.2);
        border: 2px solid $errorColor;
        border-left: none;
        border-right: none;
      }
    }

    .device-error {
      margin-top: 20px;
      padding: 0 30px;
      color: #3ac8b7;

      &.stop {
        color: $errorColor;

        .img {
          background: url("~@/assets/img/切图4.0/编组 12备份 2.png") center no-repeat;
          background-size: 100% 100%;
        }

        .info-card {
          background: rgba($errorColor, 0.1);
          border: 2px solid $errorColor;
        }
      }

      .img {
        width: 180px;
        height: 120px;
        margin-right: 28px;
        background: url("~@/assets/img/切图4.0/编组 12 (1).png") center no-repeat;
        background-size: 100% 100%;
        text-align: center;
        font-size: 28px;
      }

      .info-card {
        background: rgba(#3ac8b7, 0.1);
        border: 2px solid #3ac8b7;
        border-radius: 10px;
        padding: 10px 0;
        border-left: none;
        border-right: none;
        text-align: center;
        margin: 10px 0;
        height: 110px;

        .info {
          display: flex;
          flex-direction: column;

          .ctx {
            display: block;
            margin: auto 0;
          }
        }
      }
    }

    &.no-top {
      margin-top: 0;
    }
  }
}

.showback {
  width: 112px;
  height: 56px;
  position: absolute;
  box-sizing: border-box;
  right: 23px;
  top: 0px;
  background: url("~@/assets/img/back.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
</style>