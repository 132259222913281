<template>
  <div class="circle" ref="circle"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Circle",
  props: {
    num: {
      type: [Number, String],
      default: 0,
    },
    color: {
      type: String,
      default: "#3AC8B7",
    },
  },
  data() {
    return {
      myChart: null,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    num() {
      this.init();
    },
  },
  methods: {
    init() {
      this.myChart = this.myChart || echarts.init(this.$refs.circle);
      const num = Number(this.num || "0")
      this.myChart.setOption({
        series: {
          type: "pie",
          radius: ["75%", "85%"],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: "center",
            formatter: "{c}",
            fontSize: 24,
            color: this.color,
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            disabled: false,
            scale: false,
          },
          data: [
            {
              value: parseInt((num / 0.75) * 0.25),
              name: "other",
              itemStyle: {
                color: "rgb(255, 255, 255, 0.1)",
              },
            },
            {
              value: num,
              name: "val",
              itemStyle: {
                color: this.color,
              },
            },
          ],
        },
      });
    },
  },
};
</script>

<style scoped>
.circle {
  height: 110px;
  width: 110px;
  margin: 0 auto;
}
</style>