<template>
  <div class="mainBox" ref="dom"></div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-liquidfill";
export default {
  name: "shuiqiu",
  props: {
    num: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      myChart: null,
    };
  },
  watch: {
    num() {
      this.getDom();
    },
  },

  mounted() {
    this.getDom();
  },
  methods: {
    getDom() {
      this.myChart = this.myChart || echarts.init(this.$refs.dom);
      const num = Number(this.num)

      let color = "224, 32, 32";
      if (num > 9) {
        color = "8, 244, 218";
      } else if (num > 1) {
        color = "230, 162, 60";
      }

      const option = {
        series: [
          {
            //水的颜色
            type: "liquidFill",
            data: [num / 10, num / 9],
            color: [`rgb(${color})`, `rgba(${color}, 0.5)`],
            // center: ['10%', '50%'],
            //球的大小
            radius: "95%",
            //圆环的颜色
            outline: {
              show: true,
              borderDistance: 4,
              itemStyle: {
                color: "none",
                borderColor: `rgb(${color})`,
                borderWidth: 2,
              },
            },
            //背景颜色取消
            backgroundStyle: {
              borderColor: "none",
              color: "none",
            },
            //中间文字样式
            label: {
              fontSize: 26,
              fontWeight: 500,
              lineHeight: this.shuiqiuH,
              fontFamily: "PingFangSC-Medium, PingFang SC",
              color: "#fff",
            },
          },
        ],
      };
      this.myChart.setOption(option);
    },
  },
};
</script>
<style lang='scss' scoped>
.mainBox {
  width: 100px;
  height: 100px;
}
</style>
